<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
      <el-breadcrumb-item>CP端登录成功</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <div style="height: 100px">
        <el-button @click="open_depositVisible">一键入金</el-button>
        <el-button>一键出金</el-button>
        <el-button>账号审核</el-button>
        <el-button @click="getTest">POA-POI审核</el-button>
      </div>
      <el-tabs v-model="activeTabName" @tab-click="handleClick">
        <el-tab-pane label="入金审核" name="first">
      <el-table
        :data="deposit_info"
        style="width: 100%"
        :row-class-name="tableRowClassName"
      >
        <el-table-column label="userId" width="120" align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.userId }}</span>
          </template>
        </el-table-column>
        <el-table-column label="userName" width="180">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <div slot="reference" class="name-wrapper">
                <el-tag size="medium">{{ scope.row.userName }}</el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="email" width="250">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.email }}</span>
          </template>
        </el-table-column>
        <el-table-column label="account" width="150">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.mt4_account }}</span>
          </template>
        </el-table-column>
        <el-table-column label="入金渠道" width="130" align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{
              scope.row.payment_method
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="入金金额" width="80" align="center">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{
              scope.row.operate_amount
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="order_id" width="280">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.order_id }}</span>
          </template>
        </el-table-column>
        <el-table-column label="品牌" width="90" align="center">
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium" effect="dark" type="danger">{{
                scope.row.brand
              }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="审批状态" width="120" align="center">
          <template slot-scope="scope">
            <el-tag
              size="medium"
              v-if="scope.row.audit_status == 'success'"
              type="success"
              >{{ scope.row.audit_status }}</el-tag
            >
            <el-tag
              size="medium"
              v-if="scope.row.audit_status == 'rejected'"
              type="danger"
              >{{ scope.row.audit_status }}</el-tag
            >
            <el-tag
              size="medium"
              v-if="scope.row.audit_status == 'payment_failed'"
              type="warning"
              >{{ scope.row.audit_status }}</el-tag
            >
            <el-tag
              size="medium"
              v-if="scope.row.audit_status == null"
              type="info"
            >
              待审批
            </el-tag>
            <el-tag size="medium" v-if="scope.row.audit_status == 'pending'">
              {{ scope.row.audit_status }}
            </el-tag>
          </template>
        </el-table-column>

        <!-- <el-table-column label="申请人" width="80">
                <template slot-scope="scope">
                  <span style="margin-left: 10px">{{
                    scope.row.creator_name
                  }}</span>
                </template>
              </el-table-column> -->
        <el-table-column label="入金时间" width="200">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ scope.row.create_time }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.audit_status != 'success'"
              size="mini"
              @click="open_audit_visible(scope.row)"
              >去入金</el-button
            >
          </template>
        </el-table-column>
      </el-table></el-tab-pane>
        <el-tab-pane label="出金审核" name="second">出金审核</el-tab-pane>
        <el-tab-pane label="账号审核" name="third">账号审核</el-tab-pane>
        <el-tab-pane label="POA-POI审核" name="fourth">POA-POI审核</el-tab-pane>
      </el-tabs>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryData.currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog title="一键入金" :visible.sync="depositVisible" width="400px">
      <el-row>
        <el-form
          :model="accountForm"
          :rules="rules"
          ref="accountForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-col :span="24">
            <el-form-item label="监管" prop="regulator">
              <el-input
                v-model="accountForm.regulator"
                style="width: 221px"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-form-item label="入金账号" prop="mt4Account">
            <el-select
              v-model="accountForm.mt4Account"
              placeholder="请选择入金账号"
            >
              <el-option
                v-for="account in accountList"
                :key="account.mt4_account"
                :value="account.mt4_account"
                :label="
                  account.mt4_account +
                  '-余额:' +
                  account.balance +
                  '(' +
                  account.currency +
                  ')-' +
                  account.platform
                "
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="入金渠道" prop="paymentMethod">
            <el-select
              v-model="accountForm.paymentMethod"
              placeholder="请选择入金渠道"
            >
              <el-option label="信用卡入金" value="bridgepay"></el-option>
              <el-option label="CPS入金" value="cps_deposit"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="入金金额" prop="operateAmount">
            <el-input
              v-model="accountForm.operateAmount"
              style="width: 221px"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="deposit('accountForm')"
          >一键入金</el-button
        >
      </div>
    </el-dialog>

      <el-drawer
      title="入金审核"
      :visible.sync="audit_visible"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
    >
      <div class="demo-drawer__content">
        <el-form :model="audit_form">
          <el-form-item
            label="选择环境"
            prop="env_name"
            :label-width="formLabelWidth"
          >
            <el-select
              v-model="audit_form.env_name"
              placeholder="请选择入金环境"
              clearable
              filterable
            >
              <el-option
                v-for="item in env_list"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="品牌" :label-width="formLabelWidth">
            <el-input
              v-model="audit_form.brand"
              autocomplete="off"
              style="width: 221px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="监管" :label-width="formLabelWidth">
            <el-input
              v-model="audit_form.regulator"
              autocomplete="off"
              style="width: 221px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="order_id" :label-width="formLabelWidth">
            <el-input
              v-model="audit_form.order_id"
              autocomplete="off"
              style="width: 221px"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item
            label="审批状态"
            prop="audit_status"
            :label-width="formLabelWidth"
          >
            <el-select
              v-model="audit_form.audit_status"
              placeholder="请选择审批状态"
            >
              <el-option label="Success" value="success"></el-option>
              <el-option label="Pending" value="pending"></el-option>
              <el-option label="Rejected" value="rejected"></el-option>
              <el-option
                label="Payment Failed"
                value="payment_failed"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <div class="demo-drawer__footer">
              <el-button @click="close_audit_visible">取 消</el-button>
              <el-button type="primary" @click="audit_deposit">确 定</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
 
  </div>
</template>

<script>
import { hideLoading, showLoading } from '@/assets/js/loading';
import office_station from '../../../public/office_station.json';
export default {
  
  data() {
    return {
      env_list : [],
      formLabelWidth: "100px",
      audit_visible: false,
      audit_form: {
        env_name: '',
        brand: '',
        order_id: '',
        audit_status: '',
        regulator: '',
        user_info: '',
      },
      cp_info: {},
      deposit_info: {},
      accountList: '',
      accountForm: {
        mt4Account: '',
        operateAmount: '',
        env_name: '',
        paymentMethod: '',
        applicationNotes: '',
        redemptionCode: null,
        user_info: '',
        regulator: '',
        brand: "",
        // env_name: this.loginFrom.env_name
      },
      rules: {
        email: [
          { required: true, message: '请输入CP端登录账号', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        mt4Account: [
          { required: true, message: '请选择account', trigger: 'change' },
        ],
        operateAmount: [
          { required: true, message: '请输入入金金额', trigger: 'blur' },
        ],
        env_name: [
          { required: true, message: '请选择环境', trigger: 'change' },
        ],
        brand: [{ required: true, message: '请选择品牌', trigger: 'change' }],
        paymentMethod: [
          { required: true, message: '请选择入金类型', trigger: 'change' },
        ],
      },
      depositVisible: false,
      withdrawVisible: false,
      poa_poiVisible: false,
      accountVisible: false,
      total: 1,
      queryData: {
        size: 10,
        currentPage: 1,
        userId: '',
        order_id: '',
      },
      activeTabName: 'first',

    };
  },
  created() {
    this.activePath = window.sessionStorage.getItem('activePath');
    this.activeName = window.sessionStorage.getItem('activeName');
    this.parentName = window.sessionStorage.getItem('parentName');
    this.cp_info = JSON.parse(window.sessionStorage.getItem('cp_info'));
    this.get_deposit_list();
  },
  methods: {

    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleSizeChange(newPage) {
      /**
       * @description：用于分页
       */
      this.queryData.size = newPage;
      this.get_deposit_list();
    },
    handleCurrentChange(newCurrent) {
      /**
       * @description：用于分页
       */
      this.queryData.currentPage = newCurrent;
      this.get_deposit_list();
    },
    getEvent(res) {
      console.log(res);
    },
    tableRowClassName({ row, rowIndex }) {
      console.log(rowIndex);
      if ((rowIndex + 1) % 2 === 0) {
        return 'success-row';
      } else {
        return '';
      }
    },
    open_depositVisible() {
      this.depositVisible = true;
      this.get_cp_account_list();
    },
    async get_cp_account_list() {
      // 通过登录的CP账号，获取CP端的account账号

      const { data: res } = await this.$http.post(
        'py/server/crm/cp/get_cp_account',
        this.cp_info
      );
      if (res.success) {
        this.accountList = res.data;
        this.accountForm.regulator = this.cp_info.regulator;
      } else {
        this.$message.error(res.errmsg);
      }
    },
 
    deposit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.accountForm.user_info = this.cp_info;
          this.accountForm.env_name = this.cp_info.env_name;
          this.accountForm.brand = this.cp_info.brand;
          showLoading();
          const { data: res } = await this.$http.post(
            'py/server/crm/onClick/deposit',
            this.accountForm
          );
          hideLoading();
          if (res.success == true) {
            this.$message.success(res.msg);
            this.depositVisible = false
            this.accountForm = {}
            this.get_deposit_list();
          } else {
            this.$message.error(res.msg);
          }
        } else {
          return false;
        }
      });
    },
    async get_deposit_list() {
      this.queryData.userId = this.cp_info.userId;
      const { data: res } = await this.$http.post(
        'py/server/crm/onClick/getDepositList',
        this.queryData
      );

      if (res.success) {
        this.deposit_info = res.data.records;
        this.total = res.data.total;
        console.log(this.deposit_info)
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
    },
    async audit_deposit() {
      showLoading()
      const { data: res } = await this.$http.post(
        'py/server/crm/onClick/audit_deposit',
        this.audit_form
      );
      hideLoading()
      if (res.success) {
        this.audit_visible = false;
        this.get_deposit_list();
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
    },
    open_audit_visible(row) {
      this.audit_form.env_name = this.cp_info.env_name;
      this.audit_form.brand = row.brand;
      this.audit_form.order_id = row.order_id;
      this.audit_form.audit_status = '';

      this.audit_form.regulator = row.regulator;
      this.env_list = office_station.env;
      this.audit_visible = true;
    },
    close_audit_visible() {
      this.audit_visible = false;
    },
    getTest(){
      let params = {
        env_name : 'core-au',
        phone: '19133659856',
        brand: 'au',
        cookies: this.cp_info.cookies,
        cp_info: this.cp_info
      }
      const { data : res} = this.$http.post('py/server/crm/cp/update_client_info', params)
    }
  },
};
</script>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>