<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ obj_title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="height: 1000px;">
      <el-page-header @back="goBack"
                      :content="detailTitle"
                      style="margin-bottom: 20px">
      </el-page-header>
      <el-container style="height: 1000px; border: 1px solid #eee">
        <el-aside width="200px"
                  style="background-color: rgb(238, 241, 246)">
          <el-menu :default-openeds="['1']">
            <div>
              <el-submenu index="1">
              <template slot="title">
                {{ obj_name }}
                
                <el-button style="margin-left: 25px;" class="addStyle"
                         @click="addTab(editableTabsValue)" type="text">新增
        
              </el-button>
              </template>
              <el-menu-item v-for="(apiData, index) in apiList"
                            :key="apiData.id"
                            :index="apiData.id"
                            @click="click_function(apiData)">{{index +  1 + "* " +  apiData.title }}
                
                          </el-menu-item>
                         

  
            </el-submenu>

            </div>

          </el-menu>
        </el-aside>

        <el-container>

          <el-main>
     
            <el-tabs v-model="editableTabsValue"
                     type="card"
                     closable
                     @tab-remove="removeTab">
              <el-tab-pane v-for="(item) in editableTabs"
                           :key="item.name"
                           :label="item.title"
                           :name="item.name">
                <component :is="item.content" />
              </el-tab-pane>
            </el-tabs>

          </el-main>
        </el-container>
      </el-container>
    </el-card>

    <el-card>

      <!-- <el-button type="primary"
                 style="margin-bottom: 20px;"
                 icon="el-icon-plus"
                 @click="addApi">添加接口</el-button>
      <div>
        <el-table :data="apiList"
                  border
                  height="600px">
          <el-table-column fixed
                           prop="id"
                           label="接口编号"
                           width="120px">
          </el-table-column>
          <el-table-column prop="title"
                           label="接口描述"
                           width="250">
          </el-table-column>
          <el-table-column prop="method"
                           label="请求方式"
                           width="100px">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.method == 'post' || scope.row.method == 'POST'">{{ scope.row.method }}</el-tag>
              <el-tag v-else
                      type="success">{{ scope.row.method }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="api_path"
                           label="接口地址"
                           width="250">
          </el-table-column>
          <el-table-column prop="creator"
                           label="创建人"
                           width="120">
          </el-table-column>
          <el-table-column prop="update_time"
                           label="更新时间"
                           width="180">
          </el-table-column>

          <el-table-column label="操作"
                           width="100">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row)"
                         type="text"
                         size="small">查看</el-button>
              <el-button type="text"
                         size="small">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div> -->
      <el-dialog title="新增接口"
                 :visible.sync="addApiVisible"
                 width="60%"
                 destroy-on-close="true">
        <api-detail ref="detailRef"></api-detail>
      </el-dialog>
    </el-card>

  </div>
</template>

<script>
import apiTable from "../common/ApiTable.vue";
import apiDetail from "./ApiDetail.vue";
import apiSend from "./ApiSend.vue";

export default {
  components: {
    apiDetail,
    apiTable,
    apiSend
  },
  props: {
    data_list: [],
  },
  data () {
    return {
      test: 'apiSend',
      editableTabsValue: '0',
      editableTabs: [],
      tabIndex: 2,
      obj_id: this.$route.query.obj_id || "",
      obj_name: this.$route.query.obj_name || "",
      addApiRes: false,
      addApiVisible: false,
      apiList: [],
      tabPosition: "left",
      radio: 1,
      methodList: [
        { key: "02", value: "POST" },
        { key: "01", value: "GET" },
        { key: "03", value: "PUT" },
        { key: "04", value: "DELETE" },
      ],
      formInline: {
        api_path: "",
        method: "POST",
      },
      ruleForm: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        region: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],
        date1: [
          { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
        ],
        date2: [
          { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
        ],
        type: [
          { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
        ],
        resource: [
          { required: true, message: '请选择活动资源', trigger: 'change' }
        ],
        desc: [
          { required: true, message: '请填写活动形式', trigger: 'blur' }
        ]
      },
      items: [
        { name: "Item 1", quantity: 1, price: 10 },
        { name: "Item 2", quantity: 2, price: 20 },
        { name: "Item 3", quantity: 3, price: 30 },
      ],
      activeName: "first",
      obj_title: "",
      detailTitle: "",
      api_data: {
        id: "hahaha",
        env_name: 'beaver',
        obj: 'sws',
        obj_id: "",
        title: "",
        api_path: "/login/to_login",
        method: "post",
        data_type: "",
        headers_data: [],
        params_data: [],
        body_data: [],
        json_data: "",
        pre_data: [],
        post_data: [],
        assert_data: [],
      },
    };
  },
  created () {
    this.activePath = window.sessionStorage.getItem("activePath");
    this.activeName = window.sessionStorage.getItem("activeName");
    this.parentName = window.sessionStorage.getItem("parentName");
    this.getApiList();
    console.log("dsdsd", this.obj_name)

  },
  computed: {
    res () {
      console.log(this.$store.state.addApiResult)
      return this.$store.state.addApiResult
    }
  },
  watch: {
    res: {
      handler (newValue, oldValue) {
        console.log(newValue)
        if (newValue == true) {
          this.closeAddApiVisible()
          this.getApiList()
        }
      },
      immediate: true
    }

  },
  methods: {

    resetApiData () {
      this.api_data = {
        id: "",
        env_name: '',
        obj: '',
        obj_id: "",
        title: "",
        api_path: "",
        method: "post",
        data_type: "",
        headers_data: [],
        params_data: [],
        body_data: [],
        json_data: "",
        pre_data: [],
        post_data: []
      };
    },
    async getApiList () {

      const { data: res } = await this.$http.get(
        "py/server/autoApi/getApiList?obj_id=" + this.obj_id
      );

      if (res.success) {
        this.apiList = res.data
      } else {
        this.$message.error(res.msg)
      }

    },
    tabClick (args) {
      const tabName = args._props.name;
      const tabLabel = args._props.label;

    },
    goBack () {
      this.$router.back(0);
    },
    apiDeatilClick (events) {
      this.detailTitle = events.label;
      const api_id = events.name;

    },
    addApi () {
      this.addApiVisible = true
      this.$store.commit('changeCommit', false)
      this.$nextTick(() => {
        this.api_data.obj_id = this.obj_id
        this.$refs.detailRef.apiInfo = this.api_data
        this.$refs.detailRef.obj_id = this.obj_id;

      });

    },
    closeAddApiVisible () {
      this.addApiVisible = false
    },
    handleClick (row) {

      this.addApiVisible = true;
      this.$nextTick(() => {
        this.$refs.detailRef.apiInfo = row;
        this.$refs.detailRef.obj_id = this.obj_id;
        if (row.response != null || row.response != {}) {
          this.$refs.detailRef.res_json = row.response
        } else {
          this.$refs.detailRef.res_json = {}
        }

      });
    },
    addTab () {
      this.api_data =  {
        api_id: "",
        env_name: 'core-au',
        obj: 'sws',
        obj_id: this.obj_id,
        title: "",
        api_path: "/api/v1/auth/session/login",
        method: "post",
        data_type: 2,
        headers_data: [{"key":"", "value":"", "data_type": "string"}],
        params_data: [{"key":"", "value":"", "data_type": "string"}],
        body_data: [{"key": "userName", "value": "9693199ece62fc71747a6947651434568787760bfe5148f42de6c936d47b0588579538bd7f513d6d07a00255cd2b200cbac2b284fb4478171e34fa0c733cb67458e37790dab543728b3fa3e7a649b588339201eca72630e45764763c315fae49782c21d46266051606d99c300827f7313a4b94903c3f3674cba9ab9459f79eab","data_type": "string"}, {"key":"passWord","value":"347be92ab52e73513a949a62b5d5d24e","data_type": "string"}],
        json_data: "",
        pre_data: [],
        post_data: []
      };
      window.sessionStorage.setItem('api_data', JSON.stringify(this.api_data))

      let newTabName = this.editableTabs.length + 1
      this.editableTabs.push({
        title: '未命名',
        name: newTabName,
        content: "apiSend"
      });
      this.editableTabsValue = newTabName;

    },
    removeTab (targetName) {
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }

      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter(tab => tab.name !== targetName);
    },
    click_function (api_info) {
      window.sessionStorage.setItem('api_data', JSON.stringify(api_info))
      console.log(api_info)
      let true_number = ''

      true_number = this.editableTabs.find((item) => api_info.api_id === item.name)

      if (!true_number) {

        this.editableTabs.push({
          title: api_info.title,
          name: api_info.api_id,
          content: "apiSend"
        });
        this.editableTabsValue = api_info.api_id;
      } else {
        this.editableTabsValue = true_number.name;

      }

    }
  },
};
</script>

<style>
.el-row {
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #042a82;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

</style>
