<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>首页</el-breadcrumb-item>
      <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
      <!-- <el-breadcrumb-item>需求详情页</el-breadcrumb-item>
      <el-breadcrumb-item>新增BUG</el-breadcrumb-item> -->
    </el-breadcrumb>
    <el-card>
      <search-component
        :key="timer"
        :itemList="searchList"
        :formData="queryData"
      >
      </search-component>
    </el-card>
    <el-card>
      <el-table :data="tableData" border stripe>
        <el-table-column prop="user_id" label="User ID" width="120">
        </el-table-column>
        <el-table-column prop="user_name" label="User Name" width="300">
        </el-table-column>
        <el-table-column prop="email" label="Email" width="300">
        </el-table-column>
        <el-table-column prop="pwd" label="密码" width="120"> </el-table-column>
        <el-table-column prop="brand" label="品牌" width="60">
        </el-table-column>
        <el-table-column prop="regulator" label="监管" width="80">
        </el-table-column>
        <el-table-column prop="create_time" label="注册日期" width="160">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="open_login_visible(scope.row)"
              >CP登录</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryData.currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryData.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <el-dialog title="一键注册" :visible.sync="registerVisible">
      <el-form :model="registerForm" :rules="rules" ref="registerForm">
        <el-form-item
          label="关键字"
          :label-width="formLabelWidth"
          prop="key_word"
        >
          <el-input
            v-model="registerForm.key_word"
            autocomplete="off"
            placeholder="请输入关键字"
            style="width: 300px"
          >
          </el-input>
          <span class="grid-content bg-purple" style="margin-left: 20px"
            >样式：testcrm_关键字_...</span
          >
        </el-form-item>
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item
              label="类型"
              :label-width="formLabelWidth"
              prop="client_type"
            >
              <el-select
                v-model="registerForm.client_type"
                placeholder="请选择客户类型"
              >
                <el-option label="Lead" value="lead"></el-option>
                <el-option label="Client" value="client"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item
              label="环境"
              :label-width="formLabelWidth"
              prop="register_url"
            >
              <el-select
                v-model="registerForm.register_url"
                filterable
                clearable
                placeholder="请选择环境"
              >
                <el-option
                  v-for="item in env_list"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item
              label="注册数量"
              :label-width="formLabelWidth"
              prop="register_count"
            >
              <el-input
                v-model.number="registerForm.register_count"
                autocomplete="off"
                placeholder="请输入数量"
                oninput="value=value.replace(/[^0-9]/g,'')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item
              label="国家"
              :label-width="formLabelWidth"
              prop="country"
            >
              <el-select
                v-model="registerForm.country"
                placeholder="请选择国家"
                filterable
                clearable
              >
                <el-option
                  v-for="(value, key, index) in country_dict"
                  :key="index"
                  :label="key"
                  :value="key"
                  ><div style="display: flex; align-items: center">
                    <el-image
                      :src="`https://flagcdn.com/${value}.svg`"
                      style="width: 40px; height: 24px"
                    ></el-image>
                    <div style="margin-left: 10px">{{ key }}</div>
                  </div>
                </el-option>

                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="品牌"
              :label-width="formLabelWidth"
              prop="brand"
            >
              <el-select
                v-model="registerForm.brand"
                placeholder="请选择品牌"
                @change="getRegulation($event)"
              >
                <el-option
                  v-for="item in brand_list"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item
              label="监管"
              :label-width="formLabelWidth"
              prop="regulation"
            >
              <el-select
                v-model="registerForm.regulation"
                placeholder="请选择监管"
              >
                <el-option
                  v-for="item in regulation_list"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="cpaid" :label-width="formLabelWidth">
              <el-input
                oninput="value=value.replace(/[^0-9]/g,'')"
                v-model="registerForm.cpaid"
                autocomplete="off"
                placeholder="请输入cpaid"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="affid" :label-width="formLabelWidth">
              <el-input
                oninput="value=value.replace(/[^0-9]/g,'')"
                v-model="registerForm.affid"
                autocomplete="off"
                placeholder="请输入affid"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="cxd" :label-width="formLabelWidth">
              <el-input
                v-model="registerForm.cxd"
                autocomplete="off"
                placeholder="请输入cxd"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="leadSource" :label-width="formLabelWidth">
              <el-input
                v-model="registerForm.leadSource"
                autocomplete="off"
                placeholder="请输入leadSource"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Website ID" :label-width="formLabelWidth">
              <el-input
                v-model="registerForm.wid"
                autocomplete="off"
                placeholder="请输入Website ID"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeRegisterVisible">取 消</el-button>
        <el-button type="primary" @click="register" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.5)"
         element-loading-text="正在注册，请稍后...">注册</el-button>
      </div>
    </el-dialog>
    <el-dialog title="CP端登录" :visible.sync="loginVisible" width="400px">
      <el-form
        :model="loginFrom"
        :rules="rules"
        ref="loginFrom"
        label-width="110px"
        class="demo-ruleForm"
      >
        <el-form-item label="选择登录环境" prop="env_name">
          <el-select
            v-model="loginFrom.env_name"
            filterable
            placeholder="请选择登录环境"
            clearable
          >
            <el-option
              v-for="item in env_list"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="选择品牌" prop="brand" clearable>
          <el-select
            v-model="loginFrom.brand"
            placeholder="请选择品牌"
            disabled
          >
            <el-option
              v-for="item in brand_list"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="登录邮箱" prop="email">
          <el-input v-model="loginFrom.email" style="width: 221px"></el-input>
        </el-form-item>

        <el-form-item label="登录密码" prop="password">
          <el-input
            v-model="loginFrom.password"
            style="width: 221px; margin-right: 30px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="loginVisible = false">取 消</el-button>
          <el-button type="primary" @click="cp_login('loginFrom')"  v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.5)"
         element-loading-text="正在登录，请稍后..."
            >登 录</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="添加账号" :visible.sync="addVisible" >
     
        <el-form :model="addForm" :inline="true">
          <el-row>
          <el-col :span="10">
            <el-form-item
              label="选择品牌"
              prop="brand"
              clearable
              :label-width="formLabelWidth"
            >
              <el-select
                v-model="addForm.brand"
                placeholder="请选择品牌"
                @change="getRegulation($event)"
              >
                <el-option
                  v-for="item in brand_list"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
            <el-col :span="10">
            <el-form-item
              label="监管"
              :label-width="formLabelWidth"
              prop="regulator"
            >
              <el-select v-model="addForm.regulator" placeholder="请选择监管">
                <el-option
                  v-for="item in regulation_list"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="userId" :label-width="formLabelWidth">
            <el-input v-model="addForm.userId" autocomplete="off"></el-input>
          </el-form-item>
         </el-col>
          <el-col :span="10">
          <el-form-item label="userName" :label-width="formLabelWidth">
            <el-input v-model="addForm.userName" autocomplete="off"></el-input>
          </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="email" :label-width="formLabelWidth">
            <el-input v-model="addForm.email" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
          <el-col :span="10">
          <el-form-item label="password" :label-width="formLabelWidth">
            <el-input v-model="addForm.password" autocomplete="off"></el-input>
          </el-form-item>
          </el-col>
        </el-row>
        </el-form>


      <div slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="addAccount"
          >添加账号</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import office_station from '../../../public/office_station.json';
import { md5, rsa } from '../RSA/encrypt';
import SearchComponent from '../common/Serach.vue';
export default {
  components: {
    SearchComponent,
  },
  data() {
    return {
      addVisible: false,
      addForm: {
        userId: '',
        userName: '',
        email: '',
        password: '',
        brand: '',
        regulator: '',
      },
      loginVisible: false,
      loginFrom: {
        email: '',
        password: '',
        env_name: '',
        brand: '',
        audit_dialog: false,
        loading: false,
      },

      registerVisible: false,
      formLabelWidth: '120px',
      tableData: [],
      total: '',
      client_data: [],
      registerForm: {
        client_type: 'lead', //客户类型
        country: '', //国家
        key_word: '', //关键字
        register_url: '', //注册环境
        brand: '', //品牌
        regulation: '', //监管
        affid: '', //IB rebate account
        cxd: '', // cxd
        leadSource: '', //lead source
        cpaid: '', //cpa id
        wid: '', // Website ID
        register_count: 1, //注册量
        rsa_phone: '',
        rsa_email: ''
      },
      rules: {
        key_word: [
          { required: true, message: '请输入关键字', trigger: 'blur' },
          { min: 1, message: '长度大于 1 个字符', trigger: 'blur' },
        ],
        country: [{ required: true, message: '请选择国家', trigger: 'change' }],
        client_type: [
          { required: true, message: '请选择用户类型', trigger: 'change' },
        ],
        register_url: [
          { required: true, message: '请选择环境', trigger: 'change' },
        ],
        brand: [{ required: true, message: '请选择品牌', trigger: 'change' }],
        regulation: [
          { required: true, message: '请选择监管', trigger: 'change' },
        ],
        register_count: [
          { required: true, message: '请输入正整数', trigger: 'blur' },
        ],
        email: [
          { required: true, message: '请输入CP端登录账号', trigger: 'blur' },
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        mt4Account: [
          { required: true, message: '请选择account', trigger: 'change' },
        ],
        operateAmount: [
          { required: true, message: '请输入入金金额', trigger: 'blur' },
        ],
        env_name: [
          { required: true, message: '请选择环境', trigger: 'change' },
        ],
        paymentMethod: [
          { required: true, message: '请选择入金类型', trigger: 'change' },
        ],
      },
      loading: false,
      country_dict: {},
      env_list: [],
      brand_list: [],
      regulation_list: [],
      queryData: {
        user_id: '',
        user_name: '',
        email: '',
        size: 10,
        currentPage: 1,
      },

      searchList: [
        {
          label: 'User ID：',
          prop: 'user_id',
          type: 'input',
          change: this.getEvent,
        },
        {
          label: 'UserName：',
          prop: 'user_name',
          type: 'input',
          change: this.getEvent,
        },
        {
          label: 'Email：',
          prop: 'email',
          type: 'input',
          change: this.getEvent,
        },

        // { label: '时间：', prop: 'date', type: 'daterange', valueFormat: 'yyyy-MM-dd' },
        {
          type: 'button',
          btnList: [
            {
              type: 'primary',
              label: '查询',
              size: 'mini',
              icon: 'el-icon-search',
              handle: (row) => this.getClientList(),
            },
            {
              type: 'info',
              label: '重置',
              size: 'mini',
              icon: 'el-icon-setting',
              handle: (row) => this.reset(),
            },
            {
              type: 'primary',
              label: '一键注册',
              size: 'mini',
              icon: 'el-icon-plus',
              handle: (row) => this.oepnRegisterVisible(),
            },
            {
              type: 'success',
              label: '手动添加账号',
              size: 'mini',
              icon: 'el-icon-plus',
              handle: (row) => this.oepnAddVisible(),
            },
          ],
        },
      ],
    };
  },
  created() {
    this.activePath = window.sessionStorage.getItem('activePath');
    this.activeName = window.sessionStorage.getItem('activeName');
    this.parentName = window.sessionStorage.getItem('parentName');
  },
  mounted() {
    this.getClientList();
  },

  methods: {
    oepnRegisterVisible() {
      //打开注册弹窗
      this.registerVisible = true;
      this.getCountryDict();
      this.env_list = office_station.env;
      this.brand_list = office_station.brand;
      // this.getEnvList();
    },
    async getCountryDict() {
      // 获取国家字典
      const { data: res } = await this.$http.get(
        'py/server/crm/cp/getCountryDict'
      );
      this.country_dict = res.data;
    },
    getRegulation(brand) {
      this.regulation_list = office_station.regulation[brand];
      this.registerForm.regulation = '';
    },
    register() {

      this.$refs.registerForm.validate(async (valid) => {
        if (!valid) return;
        this.registerForm.rsa_phone = rsa(new Date().getTime())
        this.registerForm.rsa_email = rsa(new Date().getTime())
        this.loading = true
        const res = await this.$http.post(
          'py/server/crm/cp/register',
          this.registerForm
        );
        this.loading = false
        if (res.data.success) {
          this.registerVisible = false;
          this.$message.success(res.data.msg);
          this.$refs.registerForm.resetFields();
          this.getClientList();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    reset() {
      this.queryData = {
        // 添加会议弹窗表单
        address: '', // 会议室名称
        title: '', // 会议标题
        date: '', // 会议日期
        status: '', // 会议状态
        start: '', // 开始时间
        end: '', // 结束时间
        create_name: '', // 会议发起人
        current: 1,
        size: 10,
      };
    },

    async getClientList() {
      const { data: res } = await this.$http.post(
        'py/server/crm/cp/getClientList',
        this.queryData
      );

      if (res.success) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    go_to_login(row) {
      // 去登录页面
      this.$router.push({
        path: '/oneClickDeposit',
        query: {
          email: row.email,
          pwd: row.pwd,
        },
      });
    },
    handleSizeChange(newPage) {
      /**
       * @description：用于分页
       */
      this.queryData.size = newPage;
      this.getClientList();
    },
    handleCurrentChange(newCurrent) {
      /**
       * @description：用于分页
       */
      this.queryData.currentPage = newCurrent;
      this.getClientList();
      
    },
    getEvent(res) {
      console.log(this.searchData);
    },
    open_login_visible(row) {
      // CP 端登录页面
      console.log(row);
      this.env_list = office_station.env;
      this.brand_list = office_station.brand;
      this.loginFrom.brand = row.brand;
      this.loginFrom.email = row.email;
      this.loginFrom.password = row.pwd;
      this.loginVisible = true;
    },
    cp_login(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let submit = {
            email: '',
            password: '',
            env_name: '',
            brand: '',
            audit_dialog: false,
            loading: false,
          };
          submit.email = rsa(this.loginFrom.email);
          submit.password = md5(this.loginFrom.password);
          submit.env_name = this.loginFrom.env_name;
          submit.brand = this.loginFrom.brand;
          this.loading = true
          const { data: res } = await this.$http.post(
            'py/server/crm/cp/login',
            submit
          );
          this.loading = false
          if (res.success === true) {
            // this.$message.success("登录成功")
            window.sessionStorage.setItem(
              'cp_info',
              JSON.stringify(res.user_info)
            );
            this.$router.push({
              path: '/cpControl',
            });
          } else {
            this.$message.error(res.msg);
          }
        } else {
          this.loading = false
          return false;
        }
      });
    },
    oepnAddVisible() {
      this.env_list = office_station.env;
      this.brand_list = office_station.brand;
      this.addVisible = true;
    },
    addAccount(){
      this.addForm.brand = this.addForm.brand.toLowerCase()
      console.log(this.addForm)

    },
    closeRegisterVisible(){
      this.registerVisible = false
      this.loading = false
    }
  },
};
</script>

<style>
</style>