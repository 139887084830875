<template>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ parentName }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ activeName }}</el-breadcrumb-item>
      </el-breadcrumb>
      <el-card>
        <el-row>
          <el-form
            :model="loginFrom"
            :inline="true"
            :rules="rules"
            ref="loginFrom"
            label-width="120px"
            class="demo-ruleForm"
          >
            <el-col :span="8">
              <el-form-item label="选择登录环境" prop="env_name">
                <el-select
                  v-model="loginFrom.env_name"
                  filterable
                  placeholder="请选择入金环境"
                  clearable
                >
                  <el-option
                    v-for="item in env_list"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                  <!-- <el-option label="core-au"
                             value="core-au"></el-option>
                  <el-option label="core-star"
                             value="core-star"></el-option> -->
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="16">
              <el-form-item label="选择品牌" prop="brand" clearable>
                <el-select v-model="loginFrom.brand" placeholder="请选择品牌">
                  <el-option
                    v-for="item in brand_list"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="CP端登录账号" prop="email">
                <el-input
                  v-model="loginFrom.email"
                  style="width: 221px"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="登录密码" prop="password">
                <el-input
                  v-model="loginFrom.password"
                  style="width: 221px; margin-right: 30px"
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="4"> -->
            <el-button type="primary" @click="cp_login('loginFrom')"
              >一键登录并获取账户信息</el-button
            >
            <!-- </el-col> -->
          </el-form>
        </el-row>
        <div class="account_info" v-show="account_is_show">
          <el-row>
            <el-form
              :model="accountForm"
              :inline="true"
              :rules="rules"
              ref="accountForm"
              label-width="120px"
              class="demo-ruleForm"
            >
              <el-col :span="24">
                <el-form-item label="监管" prop="regulator">
                  <el-input
                    v-model="this.regulator"
                    style="width: 221px"
                    disabled
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-form-item label="入金账号" prop="mt4Account">
                <el-select
                  v-model="accountForm.mt4Account"
                  placeholder="请选择入金账号"
                >
                  <el-option
                    v-for="account in accountList"
                    :key="account.mt4_account"
                    :value="account.mt4_account"
                    :label="
                      account.mt4_account +
                      '-余额:' +
                      account.balance +
                      '(' +
                      account.currency +
                      ')-' +
                      account.platform
                    "
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="入金渠道" prop="paymentMethod">
                <el-select
                  v-model="accountForm.paymentMethod"
                  placeholder="请选择入金渠道"
                >
                  <el-option label="信用卡入金" value="bridgepay"></el-option>
                  <el-option label="CPS入金" value="cps_deposit"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="入金金额" prop="operateAmount">
                <el-input
                  v-model="accountForm.operateAmount"
                  style="width: 221px"
                ></el-input>
              </el-form-item>
  
              <el-button type="primary" @click="deposit('loginFrom')"
                >一键入金</el-button
              >
              <el-button type="primary" @click="withdrawFund('loginFrom')"
                >一键出金</el-button
              >
              <el-button @click="resetForm('accountForm')">重置</el-button>
            </el-form>
          </el-row>
        </div>
      </el-card>
      <el-card>
        <div>
          <search_component
            :key="timer"
            :itemList="searchList"
            :formData="queryData"
          >
          </search_component>
          <el-tabs v-model="tab_active" @tab-click="choise_tab">
            <el-tab-pane label="入金记录" name="first">
              <el-table :data="deposit_info" style="width: 100%">
                <el-table-column label="userId" width="120" align="center">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.userId }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="userName" width="180">
                  <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                      <div slot="reference" class="name-wrapper">
                        <el-tag size="medium">{{ scope.row.userName }}</el-tag>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column label="email" width="200">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.email }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="account" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.mt4_account
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="入金渠道" width="130" align="center">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.payment_method
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="入金金额" width="80" align="center">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.operate_amount
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="order_id" width="280">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.order_id
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="品牌" width="90" align="center">
                  <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                      <el-tag size="medium" effect="dark" type="danger">{{
                        scope.row.brand
                      }}</el-tag>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="审批状态" width="120" align="center">
                  <template slot-scope="scope">
                    <el-tag
                      size="medium"
                      v-if="scope.row.audit_status == 'success'"
                      type="success"
                      >{{ scope.row.audit_status }}</el-tag
                    >
                    <el-tag
                      size="medium"
                      v-if="scope.row.audit_status == 'rejected'"
                      type="danger"
                      >{{ scope.row.audit_status }}</el-tag
                    >
                    <el-tag
                      size="medium"
                      v-if="scope.row.audit_status == 'payment_failed'"
                      type="warning"
                      >{{ scope.row.audit_status }}</el-tag
                    >
                    <el-tag
                      size="medium"
                      v-if="scope.row.audit_status == null"
                      type="info"
                    >
                      待审批
                    </el-tag>
                    <el-tag
                      size="medium"
                      v-if="scope.row.audit_status == 'pending'"
                    >
                      {{ scope.row.audit_status }}
                    </el-tag>
                  </template>
                </el-table-column>
  
                <!-- <el-table-column label="申请人" width="80">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.creator_name
                    }}</span>
                  </template>
                </el-table-column> -->
                <el-table-column label="入金时间" width="200">
                  <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{
                      scope.row.create_time
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.audit_status != 'success'"
                      size="mini"
                      @click="go_to_audit(scope.row)"
                      >去入金</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="出金记录" name="second">
              <el-table :data="deposit_info" style="width: 100%">
                <el-table-column label="userId" width="120">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.userId }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="userName" width="180">
                  <template slot-scope="scope">
                    <el-popover trigger="hover" placement="top">
                      <div slot="reference" class="name-wrapper">
                        <el-tag size="medium">{{ scope.row.userName }}</el-tag>
                      </div>
                    </el-popover>
                  </template>
                </el-table-column>
                <el-table-column label="email" width="180">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.email }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="account" width="150">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.mt4_account
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="出金渠道" width="130">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.payment_method
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="出金金额" width="80">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.operate_amount
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="order_id" width="280">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.order_id
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="审批状态" width="120">
                  <template slot-scope="scope">
                    <el-tag
                      size="medium"
                      v-if="scope.row.audit_status == 'success'"
                      type="success"
                      >{{ scope.row.audit_status }}</el-tag
                    >
                    <el-tag
                      size="medium"
                      v-if="scope.row.audit_status == 'rejected'"
                      type="danger"
                      >{{ scope.row.audit_status }}</el-tag
                    >
                    <el-tag
                      size="medium"
                      v-if="scope.row.audit_status == 'payment_failed'"
                      type="warning"
                      >{{ scope.row.audit_status }}</el-tag
                    >
                    <el-tag
                      size="medium"
                      v-if="scope.row.audit_status == null"
                      type="info"
                    >
                      待审批
                    </el-tag>
                    <el-tag
                      size="medium"
                      v-if="scope.row.audit_status == 'pending'"
                    >
                      {{ scope.row.audit_status }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="品牌-监管" width="90">
                  <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                      <span size="medium">{{
                        scope.row.brand + '-' + scope.row.regulator
                      }}</span>
                    </div>
                  </template>
                </el-table-column>
                <!-- <el-table-column label="申请人" width="80">
                  <template slot-scope="scope">
                    <span style="margin-left: 10px">{{
                      scope.row.creator_name
                    }}</span>
                  </template>
                </el-table-column> -->
                <el-table-column label="出金时间" width="200">
                  <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{
                      scope.row.create_time
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <el-button
                      v-if="scope.row.audit_status != 'success'"
                      size="mini"
                      @click="go_to_audit(scope.row)"
                      >去出金</el-button
                    >
                  </template>
                </el-table-column>
              </el-table></el-tab-pane
            >
          </el-tabs>
        </div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryData.currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </el-card>
  
      <el-drawer
        title="入金审核"
        :visible.sync="audit_dialog"
        direction="rtl"
        custom-class="demo-drawer"
        ref="drawer"
      >
        <div class="demo-drawer__content">
          <el-form :model="audit_form">
            <el-form-item
              label="选择环境"
              prop="env_name"
              :label-width="formLabelWidth"
            >
              <el-select
                v-model="audit_form.env_name"
                placeholder="请选择入金环境"
                clearable
                filterable
              >
                <el-option
                  v-for="item in env_list"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
  
            <el-form-item label="品牌" :label-width="formLabelWidth">
              <el-input
                v-model="audit_form.brand"
                autocomplete="off"
                style="width: 221px"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="监管" :label-width="formLabelWidth">
              <el-input
                v-model="audit_form.regulator"
                autocomplete="off"
                style="width: 221px"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="order_id" :label-width="formLabelWidth">
              <el-input
                v-model="audit_form.order_id"
                autocomplete="off"
                style="width: 221px"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item
              label="审批状态"
              prop="audit_status"
              :label-width="formLabelWidth"
            >
              <el-select
                v-model="audit_form.audit_status"
                placeholder="请选择审批状态"
              >
                <el-option label="Success" value="success"></el-option>
                <el-option label="Pending" value="pending"></el-option>
                <el-option label="Rejected" value="rejected"></el-option>
                <el-option
                  label="Payment Failed"
                  value="payment_failed"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label-width="formLabelWidth">
              <div class="demo-drawer__footer">
                <el-button @click="cancelForm">取 消</el-button>
                <el-button type="primary" @click="audit_deposit">确 定</el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-drawer>
    </div>
  </template>
  
  <script>
  import office_station from '../../../public/office_station.json';
  
  import { hideLoading, showLoading } from '@/assets/js/loading';
import { md5, rsa } from '../RSA/encrypt';
import search_component from '../common/Serach.vue';
  export default {
    components: {
      search_component,
    },
    data() {
      return {
        total: 1,
        queryData:{
          userId : '',
          order_id: '',
          size: 10,
          currentPage: 1
        },
        loginFrom: {
          email: '',
          password: '',
          env_name: '',
          brand: '',
          audit_dialog: false,
          loading: false,
        },
      
        searchList: [
          {
            label: 'userId',
            prop: 'userId',
            type: 'input',
            change: this.getEvent,
            size: 'mini',
          },
          {
            label: 'order_id：',
            prop: 'order_id',
            type: 'input',
            change: this.getEvent,
          },
          {
            type: 'button',
            btnList: [
              {
                type: 'primary',
                label: '查询',
                size: 'mini',
                icon: 'el-icon-search',
                handle: (row) => this.get_deposit_list(),
              },
              {
                type: 'info',
                label: '重置',
                size: 'mini',
                icon: 'el-icon-setting',
                handle: (row) => this.reset(),
              },
            ],
          },
        ],
        cookies: '',
        tab_active: 'first',
        accountList: [],
        accountForm: {
          mt4Account: '',
          operateAmount: '',
          env_name: '',
          paymentMethod: '',
          applicationNotes: '',
          redemptionCode: null,
          // env_name: this.loginFrom.env_name
        },
        audit_dialog: false,
        audit_form: {
          env_name: '',
          brand: '',
          order_id: '',
          audit_status: '',
          regulator: '',
        },
        env_list: [],
        brand_list: [],
        formLabelWidth: '80px',
        timer: null,
        regulator: '',
  
        deposit_info: [],
  
        user_data: {},
        account_is_show: false,
        rules: {
          email: [
            { required: true, message: '请输入CP端登录账号', trigger: 'blur' },
            // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
          ],
          password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
          mt4Account: [
            { required: true, message: '请选择account', trigger: 'change' },
          ],
          operateAmount: [
            { required: true, message: '请输入入金金额', trigger: 'blur' },
          ],
          env_name: [
            { required: true, message: '请选择环境', trigger: 'change' },
          ],
          brand: [{ required: true, message: '请选择品牌', trigger: 'change' }],
          paymentMethod: [
            { required: true, message: '请选择入金类型', trigger: 'change' },
          ],
          // date1: [
          //   { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
          // ],
          // date2: [
          //   { type: 'date', required: true, message: '请选择时间', trigger: 'change' }
          // ],
          // type: [
          //   { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
          // ],
          // resource: [
          //   { required: true, message: '请选择活动资源', trigger: 'change' }
          // ],
          // desc: [
          //   { required: true, message: '请填写活动形式', trigger: 'blur' }
          // ]
        },
      };
    },
    created() {
      this.activePath = window.sessionStorage.getItem('activePath');
      this.activeName = window.sessionStorage.getItem('activeName');
      this.parentName = window.sessionStorage.getItem('parentName');
  
      this.get_deposit_list();
  
      this.env_list = office_station.env;
      this.brand_list = office_station.brand
    },
    methods: {
      deposit(formName) {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            this.accountForm.env_name = this.loginFrom.env_name;
            this.accountForm.brand = this.loginFrom.brand;
            this.accountForm.cookies = this.cookies;
            this.accountForm.user_data = this.user_data;
            showLoading()
            const { data: res } = await this.$http.get(
              'py/server/crm/onClick/deposit'
            );
            hideLoading()
            if (res.success == true) {
              this.$message.success(res.msg);
              this.get_deposit_list();
            } else {
              this.$message.error(res.msg);
            }
          } else {
            return false;
          }
        });
      },
      withdrawFund() {
        console.log('一键出金');
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.account_is_show = false;
      },
      async get_deposit_list() {
      
        const { data: res } = await this.$http.post(
          'py/server/crm/onClick/getDepositList', this.queryData
        );
  
        if (res.success) {
          this.$message.success(res.msg);
          this.deposit_info = res.data.records;
          this.total = res.data.total
  
        } else {
          this.$message.error(res.msg);
        }
      },
      go_to_audit(row) {
        this.audit_form.env_name = '';
        this.audit_form.brand = row.brand;
        this.audit_form.order_id = row.order_id;
        this.audit_form.audit_status = '';
        this.audit_form.regulator = row.regulator;
        this.audit_dialog = true;
      },
      cancelForm() {
        this.audit_dialog = false;
      },
      async audit_deposit() {
        showLoading()
        const { data: res } = await this.$http.post(
          'py/server/crm/onClick/audit_deposit',
          this.audit_form
        );
        hideLoading()
        if (res.success) {
          this.audit_dialog = false;
          this.get_deposit_list();
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      },
  
      reset () {
        
        this.queryData = {
          userId: '', // 可以赋予初始值
          order_id: '',
          size: 10,
          currentPage: 1
      
        }
        
        this.get_deposit_list()
  
       
      },
      cp_login(formName) {
  
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            let submit = {
              email: '',
              password: '',
              env_name: '',
              brand: '',
              audit_dialog: false,
              loading: false,
            };
            submit.email = rsa(this.loginFrom.email);
            submit.password = md5(this.loginFrom.password);
            submit.env_name = this.loginFrom.env_name;
            submit.brand = this.loginFrom.brand;
            showLoading()
            const { data: res } = await this.$http.post(
              'py/server/crm/cp/login',
              submit
            );
            hideLoading()
            if (res.code === 0) {
              // this.$message.success("登录成功")
              this.account_is_show = true;
              this.accountList = res.data;
              this.cookies = res.cookies;
              this.user_data = res.user_data;
              this.regulator = res.user_data.regulator;
            } else {
              this.$message.error(res.msg);
            }
          } else {
            hideLoading()
            return false;
          }
        });
      
      },
  
      choise_tab(tab, event) {
        console.log(event);
        console.log(tab);
      },
      handleSizeChange(newPage) {
        /**
         * @description：用于分页
         */
        this.queryData.size = newPage;
        this.get_deposit_list()
      },
      handleCurrentChange(newCurrent) {
        /**
         * @description：用于分页
         */
        this.queryData.currentPage = newCurrent;
        this.get_deposit_list()
      },
      getEvent(res){
        console.log(res)
      }
    },
  };
  </script>
  
  <style>
  </style>